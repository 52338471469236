import {Component, OnInit} from '@angular/core';
import {MessageService} from "../../services/message.service";
import {MatSnackBar} from "@angular/material";
import {ApiService} from "../../api/api.service";
import {Club} from "../../dataModules/club";
import {AuthenticationService} from "../../services/authentication.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  username: string = "";
  password: string = "";
  clubs: Club[];

  constructor(private _router: Router, public snackBar: MatSnackBar, private apiService: ApiService, private _service: AuthenticationService) {
  }

  ngOnInit() {
    if (this._service.isLogined()) {
      this._router.navigate(["search"]);
    }
  }

  loginClick() {
    this.apiService.userLogin(this.username, this.password).subscribe(user=> {
      if (user['result'] == 0) {
        console.log("fail");
        this.openSnackBar("帳號/密碼錯誤，請再嘗試。");
      } else {
        console.log(user.username);
        this._service.login(user);
        this.openSnackBar("登入成功");
        if (this._service.isAdmin()) {
          this._router.navigate(["websiteUser"]);
        } else {
          this._router.navigate([""]);
        }
      }
    });
  }

  openSnackBar(text) {
    this.snackBar.open(text, null, {duration: 2000, verticalPosition: 'top'});
  }
}
