import {Component} from '@angular/core';
import {AuthenticationService} from "./services/authentication.service";
import {ApiService} from "./api/api.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = '帳號控制平台';
  isLogin = false;


  constructor(private apiService: ApiService, public _service: AuthenticationService) {
    console.log("AppModule");
    this.apiService.getDistricts().subscribe(districts=> {
      this._service.districts = districts;
    });

    this.apiService.getClubs().subscribe(clubs=> {
      this._service.clubs = clubs;
    });

  }


  logout() {
    this._service.logout("");
  }


}
