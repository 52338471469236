import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";

@Component({
  selector: 'app-website-user',
  templateUrl: 'website-user-dialog.component.html',
  styleUrls: ['website-user-dialog.component.css']
})
export class WebsiteUserDialogComponent {

  // acc = {username: "", password: ""};
  nameE = false;
  pwE = false;
  isUpdate = false;

  constructor(public dialogRef: MatDialogRef<WebsiteUserDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    if (data.username != null && data.username.length != 0) {
      this.isUpdate = true;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onEnterClick(): void {
    this.nameE = false;
    this.pwE = false;

    if (this.data.username == null || this.data.username.length == 0) {
      this.nameE = true;
      return;
    }
    if (this.data.password == null ||this.data.password.length == 0) {
      this.pwE = true;
      return;
    }
    this.dialogRef.close(this.data);
  }


}
