import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../base.component";
import {GameUser} from "../../dataModules/game_user";
import {ApiService} from "../../api/api.service";
import {AuthenticationService} from "../../services/authentication.service";
import {MatSnackBar, MatDialog, MatTableDataSource} from "@angular/material";
import {GameUserDialogComponent} from "../../dialog/game-user-dialog/game-user-dialog.component";

@Component({
  selector: 'app-game-user',
  templateUrl: './game-user.component.html',
  styleUrls: ['./game-user.component.css']
})
export class GameUserComponent extends BaseComponent {
  gameUsers: GameUser[];
  displayedColumns: string[] = ['dis', 'id', 'name', 'pw', 'club', 'status'];
  currentGU;
  myStatus = [
    {text: "全部", value: 0},
    {text: "正常", value: 1},
    {text: "待確認", value: 2},
    {text: "使用中", value: 3}];
  myStatusValue = 0;

  constructor(public dialog: MatDialog, private apiService: ApiService, snackBar: MatSnackBar, service: AuthenticationService) {
    super(snackBar, service);
  }

  myNgOnInit() {
    this.loadData();
  }

  loadData() {
    this.apiService.getGameUsers().subscribe(gameUsers => {
      this.gameUsers = gameUsers;
      for (var i = 0; i < gameUsers.length; i++) {
        this.gameUsers[i] = new GameUser(gameUsers[i]);
      }
      this.currentGU = this.gameUsers.slice();
      this.filter(0);
      // this.tmp = new MatTableDataSource(this.gameUsers);
    });
  }

  filter(v) {
    this.currentGU = [];
    for (var i = 0; i < this.gameUsers.length; i++) {
      if (v == 0 || this.gameUsers[i].status == v) {
        this.currentGU.push(this.gameUsers[i]);
      }
    }
  }

  itemClicked(item) {
    console.log(item);
    this.openDialog(item);
  }

  openDialog(gameUser?) {
    var isUpdate = gameUser != null;
    const dialogRef = this.dialog.open(GameUserDialogComponent, {
      width: '800px',
      data: {gameUser: gameUser}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        if (!isUpdate) {
          this.addGameUser(result);
        } else {
          this.updateGameUser(gameUser._id, result);
        }
      }
    });
  }

  addGameUser(name) {
    this.apiService.addGameUser(name).subscribe(res => {
      if (res['result'] == 0) {
        this.openSnackBar("新增遊戲帳號失敗，請重新嘗試。");
        return;
      }

      this.loadData();
      this.openSnackBar("新增遊戲帳號成功。");
    });
  }

  updateGameUser(id, user) {
    this.apiService.updateGameUser(id, user).subscribe(res => {
      if (res['result'] == 0) {
        this.openSnackBar("更新遊戲帳號失敗，請重新嘗試。");
        return;
      }

      this.loadData();
      this.openSnackBar("更新遊戲帳號成功。");
    });
  }

  re_searchForOne() {

  }
}
