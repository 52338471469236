import {Injectable} from '@angular/core';
import {AppRoutingModule} from "../app-routing.module";
import {Router} from "@angular/router";
import {WebsiteUser} from "../dataModules/website_user";
import {Club} from "../dataModules/club";
import {District} from "../dataModules/district";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private _router: Router) {
  }

  checkCredentials() {
    if (!this.isLogined()) {
      this._router.navigate(["login"]);
      return false;
    } else {
      return true;
    }
  }

  isLogined() {
    return (localStorage.getItem("id") != null
      && localStorage.getItem("name") != null
      && localStorage.getItem("token") != null
    );
  }

  logout(msg) {
    localStorage.removeItem("id");
    localStorage.removeItem("name");
    localStorage.removeItem("token");
    this._router.navigate(["login", {msg: msg}]);
  }


  login(user: WebsiteUser) {
    localStorage.setItem("id", user._id + "");
    localStorage.setItem("name", user.username);
    localStorage.setItem("token", user.token);
    if (user._id == 99) {
      localStorage.setItem("isAdmin", "1");
    } else {
      localStorage.setItem("isAdmin", "0");
    }

    this._router.navigate(["search"]);
  }

  isAdmin() {
    return localStorage.getItem("isAdmin") == "1";
  }


  loginAdmin(id, name, email, access_token, permission) {
    localStorage.setItem("id", id);
    localStorage.setItem("name", name);
    localStorage.setItem("email", email);
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("permission", permission);
    // this._router.navigate([Constants.PATH_INDIVIDUALS]);
  }


  private _districts: District[];
  private _clubs: Club[];

  getDistrictById(id) {
    if (this._districts == null) {
      return "沒有地區";
    }
    for (let d of this._districts) {
      if (d._id == id) {
        return d.name;
      }
    }

    return "沒有這地區";
  }

  getClubById(id) {
    if (this._clubs == null) {
      return "沒有倶樂部";
    }

    if (id == 0 || id == null) {
      return "-";
    }
    for (let d of this._clubs) {
      if (d._id == id) {
        return d.name;
      }
    }
    return id;
  }

  getClubsById(ids) {
    if (this._clubs == null) {
      return "沒有倶樂部";
    }

    var result = "";

    for (var i = 0; i < ids.length; i++) {
      if (ids[i] == 0 || ids[i] == null) {
        return "-";
      }
      for (let d of this._clubs) {
        if (d._id == ids[i]['club_id']) {
          if (result.length == 0) {
            result += d.name;
          } else {
            result += "," + d.name;
          }
          break;
        }
      }
    }

    return result;
  }

  get districts(): District[] {
    return this._districts;
  }

  set districts(value: District[]) {
    this._districts = value;
  }


  get clubs(): Club[] {
    return this._clubs;
  }

  set clubs(value: Club[]) {
    this._clubs = value;
  }
}
