import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../base.component";
import {WebsiteUser} from "../../dataModules/website_user";
import {ApiService} from "../../api/api.service";
import {MatSnackBar, MatDialog} from "@angular/material";
import {AuthenticationService} from "../../services/authentication.service";
import {WebsiteUserDialogComponent} from "../../dialog/website-user/website-user-dialog.component";

@Component({
  selector: 'app-website-user',
  templateUrl: './website-user.component.html',
  styleUrls: ['./website-user.component.css']
})
export class WebsiteUserComponent extends BaseComponent {
  websiteUsers: WebsiteUser[];
  displayedColumns: string[] = ['id', 'name', 'pw'];

  constructor(public dialog: MatDialog, private apiService: ApiService, snackBar: MatSnackBar, service: AuthenticationService) {
    super(snackBar, service);
  }

  myNgOnInit() {
    this.loadData();
  }

  loadData() {
    this.apiService.getWebsiteUsers().subscribe(websiteUsers=> {
      this.websiteUsers = websiteUsers;
    });
  }


  itemClicked(item) {
    // console.log("asd");
    console.log(item);
    this.openDialog(item._id, item.username, item.password);
  }

  openDialog(id?, username?, password?) {
    var isUpdate = username != null;
    const dialogRef = this.dialog.open(WebsiteUserDialogComponent, {
      width: '500px',
      data: {username: username, password: password}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        if (!isUpdate) {
          this.addWebUser(result);
        } else {
          this.updateWebUser(id, result);
        }
      }
    });
  }

  addWebUser(name) {
    this.apiService.addWebUser(name).subscribe(res=> {
      if (res['result'] == 0) {
        this.openSnackBar("新增網頁帳號失敗，請重新嘗試。");
        return;
      }

      this.loadData();
      this.openSnackBar("新增網頁帳號成功。");
    });
  }

  updateWebUser(id, name) {
    this.apiService.updateWebUser(id, name).subscribe(res=> {
      if (res['result'] == 0) {
        this.openSnackBar("更新網頁帳號失敗，請重新嘗試。");
        return;
      }

      this.loadData();
      this.openSnackBar("更新網頁帳號成功。");
    });
  }
}
