import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../base.component";
import {MatDialog, MatSnackBar} from "@angular/material";
import {ApiService} from "../../api/api.service";
import {AuthenticationService} from "../../services/authentication.service";
import {GameUser} from "../../dataModules/game_user";
import {UserLog} from "../../dataModules/UserLog";

@Component({
  selector: 'app-user-log',
  templateUrl: './user-log.component.html',
  styleUrls: ['./user-log.component.css']
})
export class UserLogComponent extends BaseComponent {

  userLogs: UserLog[];

  constructor(public dialog: MatDialog, private apiService: ApiService, snackBar: MatSnackBar, service: AuthenticationService) {
    super(snackBar, service);
  }

  myNgOnInit() {
    this.loadData();
  }

  loadData() {
    this.apiService.getUserLogs().subscribe(userLogs => {
      this.userLogs = userLogs;
    });
  }

}
