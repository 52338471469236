import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../base.component";
import {AuthenticationService} from "../../services/authentication.service";
import {Club} from "../../dataModules/club";
import {District} from "../../dataModules/district";
import {ApiService} from "../../api/api.service";
import {MatSnackBar} from "@angular/material";
import {isUndefined} from "util";
import {GameUser} from "../../dataModules/game_user";
import {forEach} from "@angular/router/src/utils/collection";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent extends BaseComponent {
  districts: District[];
  clubs: Club[];
  selectedClubs: number[];
  gameUsers: GameUser[] = null;
  displayedColumns: string[] = ['district', 'club', 'user_id', 'account', 'pw', 'status'];
  isConfirmed: boolean;

  constructor(private apiService: ApiService, public snackBar: MatSnackBar, _service: AuthenticationService) {
    super(snackBar, _service);
  }

  myNgOnInit() {
    this.apiService.getDistricts().subscribe(districts=> {
      this.selectedClubs = new Array<number>(districts.length);
      this.districts = districts;
      this._service.districts = this.districts;
      if (this._service.clubs != null) {
        this.apiService.getCurrentGameAccount().subscribe(
          res=>this.setGameUsers(res));
      }
    });

    this.apiService.getClubs().subscribe(clubs=> {
      this.clubs = clubs;
      this._service.clubs = this.clubs;
      if (this._service.districts != null) {
        this.apiService.getCurrentGameAccount().subscribe(
          res=>this.setGameUsers(res));
      }
    });
    // this.apiService.getCurrentGameAccount().subscribe(
    //   res=> {
    //     this.setGameUsers(res);
    //   });


  }

  private setGameUsers(res) {
    if (res['result'] == 0) {
      return;
    }
    this.isConfirmed = false;
    this.gameUsers = res;
    for (var i = 0; i < this.gameUsers.length; i++) {
      this.gameUsers[i] = new GameUser(this.gameUsers[i]);
      if (this.gameUsers[i].status == 3) {
        this.isConfirmed = true;
      }
    }
  }

  private setGameUser(res: GameUser) {
    if (res['result'] == 0) {
      return;
    }
    for (var i = 0; i < this.gameUsers.length; i++) {
      if (this.gameUsers[i].district_id == res.district_id) {
        this.gameUsers[i].copy(res);
        return;
      }
    }
  }

  search() {
    console.log(this.selectedClubs);
    if (!this.validation()) {
      this.openSnackBar("請先選擇所有的倶樂部");
      return;
    }

    this.apiService.searchGameUser(this.selectedClubs).subscribe(
      res=> {
        for (let gameUser of res) {
          gameUser.status = 2;
        }
        this.setGameUsers(res);
      });
  }

  re_searchForOne(district_id, club_id){
    this.apiService.searchGameUserForOne(district_id, club_id).subscribe(
      res => {
        this.setGameUser(res);
      });

  }

  validation() {
    for (var i = 0; i < this.selectedClubs.length; i++) {
      if (!this.selectedClubs[i] || this.selectedClubs[i] == null
        || this.selectedClubs[i] == 0) {
        return false;
      }
    }
    return true;
  }

  research() {
    this.clear();
  }

  confirmUser() {
    this.apiService.comfirmGameUser().subscribe(res=> {
      for (let gameUser of this.gameUsers) {
        gameUser.status = 3;
      }
      this.isConfirmed = true;
    });
  }

  clear() {
    this.apiService.clearGameUser().subscribe(res=> {
      this.isConfirmed = false;
      this.gameUsers = null;
    });
  }
}
