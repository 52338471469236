import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-hack-add-user',
  templateUrl: './hack-add-user.component.html',
  styleUrls: ['./hack-add-user.component.css']
})
export class HackAddUserComponent implements OnInit {

  // dis:string;


  text: string;
  result: string = "nothing";

  constructor() {
  }

  ngOnInit() {
  }

  click() {
    this.result = "";
    var _id = 1;
    var gupNum = [0,0,0,0,0,0,0,0,0,0,0];

    var rowArray = this.text.split("\n");
    for (let row of rowArray) {
      // each row

      var col = row.split("	");
      var dis = col[0].substr(0, 1);

      var acc = col[1];
      var name = col[2];
      var password = col[3];
      var clubs = [];
      for (var i = 4; i < col.length; i++) {
        switch (col[i]) {
          case "皇":
            clubs.push(1);
            break;
          case "招":
            clubs.push(2);
            break;
          case "魚":
            clubs.push(3);
            break;
          case "艦隊":
            clubs.push(4);
            break;
          case "蕉":
            clubs.push(5);
            break;
          case "瑞":
            clubs.push(6);
            break;
          case "招奥":
            clubs.push(7);
            break;
          case "雀":
            clubs.push(8);
            break;
          case "画":
            clubs.push(9);
            break;
          case "雀奥":
            clubs.push(10);
            break;
        }
      }

      gupNum[dis]++;
      this.result += "INSERT INTO GameUser (_id, user_id, username, password, district_id) VALUES (" + _id + "," + acc +
        ",'" + name + "','" + password + "'," + dis + ");<br/>";
      for (let c of clubs) {
        this.result += "INSERT INTO GameUserClub (game_user_id, club_id) VALUES (" + _id + "," + c + ");<br/>";
      }

      _id++;
      // console.log(clubs);
    }
    console.log(gupNum);
    // this.result = this.text;
  }

}
