import {Component, OnInit, Inject} from '@angular/core';
import {AuthenticationService} from "../../services/authentication.service";
import {MatSnackBar, MatDialog, MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {BaseComponent} from "../base.component";
import {ApiService} from "../../api/api.service";
import {District} from "../../dataModules/district";
import {Club} from "../../dataModules/club";
import {TwoValueDialogComponent} from "../../dialog/two-value-dialog/two-value-dialog.component";

@Component({
  selector: 'app-club',
  templateUrl: './club.component.html',
  styleUrls: ['./club.component.css']
})
export class ClubComponent extends BaseComponent {
  clubs: Club[];
  displayedColumns: string[] = ['id', 'name'];

  constructor(public dialog: MatDialog, private apiService: ApiService, snackBar: MatSnackBar, service: AuthenticationService) {
    super(snackBar, service);
  }

  myNgOnInit() {
    this.loadClubs();
  }

  loadClubs() {
    this.apiService.getClubs().subscribe(clubs=> {
      this.clubs = clubs;
      this._service.clubs = this.clubs;
    });
  }

  add() {
    const dialogRef = this.dialog.open(TwoValueDialogComponent, {
      width: '250px',
      data: {title: "倶樂部"}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.addClub(result);
      }
    });
  }

  addClub(name) {
    this.apiService.addClub(name).subscribe(res=> {
      if (res['result'] == 0) {
        this.openSnackBar("新增倶樂部失敗，請重新嘗試。");
        return;
      }

      this.loadClubs();
      this.openSnackBar("新增倶樂部成功。");

    });
  }

}

// @Component({
//   selector: 'app-two-value-dialog',
//   templateUrl: './two-value-dialog.component.html'
// })
// export class TwoValueDialogComponent {
//
//   constructor(public dialogRef: MatDialogRef<TwoValueDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
//
//   }
//
//
//   onNoClick(): void {
//     this.dialogRef.close();
//   }
//
// }
