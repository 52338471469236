import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, ErrorStateMatcher} from "@angular/material";
import {Validators, FormControl, NgForm, FormGroupDirective} from "@angular/forms";
// import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";


@Component({
  selector: 'app-two-value-dialog',
  templateUrl: './two-value-dialog.component.html'
})

export class TwoValueDialogComponent {

  name = "";
  isShowError = false;

  constructor(public dialogRef: MatDialogRef<TwoValueDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onEnterClick(): void {
    console.log(this.isShowError);
    if (this.name.length == 0) {
      this.isShowError = true;
      return;
    }
    this.dialogRef.close(this.name);
  }

}
